import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

const my404 = () => {
  useEffect(() => {
    navigate('/');
  }, []);
  return null;
};

export default my404;
